.newFormWrapper{
  display:grid;
  grid-template-columns:1fr;
  gap:20px;
  margin-top:30px
}
.hotelsFormWrapper{
  display:grid;
  grid-template-columns:1fr 1fr;
  gap:20px;
  margin-top:30px
}
.hotelsFormWrapper .hotelIconUpload{
  display:flex;
  gap:10px;
  height:auto;
  align-items:center;
  color:gray
}
.attendanceFilterDiv{
  margin-bottom: 2%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.attendanceFilterRedeemDiv{
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.attendanceFilterDiv .card {
  /* background-color: rgb(205, 205, 205);
  color: white; */
}
.attendanceSearchDiv{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
}
.attendanceCountDiv{
  display: flex;
  justify-content: center;
  margin-bottom: 1%;
}
.attendanceCountDiv .attendanceCount{
  width: 20%;
   background-color: #45677e;
  /* background-image: linear-gradient(#e187e8,#a09fcf); */
  text-align: center;
  padding: 1%;
  color: #fff;
}
.attendanceCountDiv .staffCount{
  width: 20%;
  background-color: #7ab0e8;
  text-align: center;
  /* height: 35px; */
  padding: 1%;
  color: #fff;
}
.attendanceCountDiv .specialpayButton{ 
  width: 15%;
  background-color: #6d41a1 !important;
  text-align: center;
  /* height: 35px; */
  padding: 4px;
  color: #fff;
}
.attendanceCountDiv  .addCsvButton{
  width:15%;
  background-color: #71d021  !important;
  text-align: center;
  padding: 4px;
  color: #fff;
 }
.attendanceformWrapper{
  display:grid;
  grid-template-columns:1fr 1fr;
  gap:20px;margin-top:30px
}
 .heading{
  margin-bottom: 20px !important;
  color: black;
  font-size: 20px !important;
  text-transform: uppercase;
  font-weight: bold !important;
 }
 .attendanceContainer .slotsection{
  background-color: rgb(239, 241, 247);
  min-height: 290px;
  width: 100%;
  border-radius: 4px;
  margin-right: 7px;
  border: 1px solid #d2d2d2;
  padding: 2%;
  margin-bottom: 5px;
  margin-top: 10px;
 }
 .slotsection .heading{
  margin-bottom: 20px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
 }
 .slotsection .textfield{
  display:grid;
  grid-template-columns:1fr 1fr;
  gap:20px;margin-top:30px
 }
 .hotelsFormWrapper p{
color: #d32f2f;
font-size: 14px;
 }
 .attendanceSearchDiv .addFinanceButton{
  width: 20%;
  background-color: #0c3547  !important;
  text-align: center;
  padding: 2px; 
  color: #fff;
 }
 .attendanceSearchDiv .addCatagoryButton{
  width: 20%;
  background-color: #0c3547  !important;
  text-align: center;
  padding: 2px;
  color: #fff;
 }
 .attendanceSearchDiv .addCsvButton{
  width: 20%;
  background-color: #71d021  !important;
  text-align: center;
  padding: 2px;
  color: #fff;
 }