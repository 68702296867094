.formcontainer{
    border: 1px solid #e8e7e7;
    background: #f2f2f2;
    padding: 3%;
    height: 100vh;
    width: 100%;
    text-align: center;
}
.selfAttendanceFormWrapper{
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    gap:20px;
    margin-top:30px;
   
}
.formcontainer .heading{
    font-family: 'monospace';
    color: rgb(24, 24, 50);
    font-size: 25px !important;
    text-transform: uppercase;
    font-weight: bold !important;
    line-height: 20px !important;
}
.formcontainer .buttonContainer{
    display: flex;
    width: 25%;
    justify-content: space-around;
    margin: auto;
    margin-top: 13px;
}
