.hotelsFormWrapper{
  display:grid;
  grid-template-columns:1fr 1fr;
  gap:20px;
  margin-top:30px
}
.hotelsFormWrapper .hotelIconUpload{
  display:flex;
  gap:10px;
  height:auto;
  align-items:center;
  color:gray
}
.attendanceFilterDiv{
  margin-bottom: 2%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.attendanceFilterRedeemDiv{
  margin-bottom: 2%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.attendanceFilterDiv .card {
  /* background-color: rgb(205, 205, 205);
  color: white; */
}
.attendanceSearchDiv{
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}
.attendanceCountDiv{
  display: flex;
  justify-content: center;
  margin-bottom: 1%;
}
.attendanceCount{
  width: 20%;
  background-color: #005c68;
  text-align: center;
  padding: 1%;
  color: #fff;
}
.staffCount{
  width: 20%;
  background-color: #4c5008;
  text-align: center;
  /* height: 35px; */
  padding: 1%;
  color: #fff;
}
.attendanceformWrapper{
  display:grid;
  grid-template-columns:1fr 1fr;
  gap:20px;margin-top:30px
}
 .heading{
  margin-bottom: 20px !important;
  color: black;
  font-size: 20px !important;
  text-transform: uppercase;
  font-weight: bold !important;
 }
 .slotsection{
  background-color: rgb(239, 241, 247);
  min-height: 290px;
  width: 100%;
  border-radius: 4px;
  margin-right: 7px;
  border: 1px solid #d2d2d2;
  padding: 2%;
  margin-bottom: 5px;
  margin-top: 10px;
 }
 .slotsection .heading{
  margin-bottom: 20px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
 }
 .slotsection .textfield{
  display:grid;
  grid-template-columns:1fr 1fr;
  gap:20px;margin-top:30px
 }
 .hotelsFormWrapper p{
color: #d32f2f;
font-size: 14px;
 }